import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Descriptions, Modal, message } from 'antd';
import axios from 'axios'
import { generateCSV } from '../../utils/helpres';

const User = () => {
    const [user, setUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [providersStats, setProvidersStats] = useState({});
    const { id, type } = useParams()
    const { navigate } = useNavigate()

    const fetchUsers = useCallback(() => {
        setUser(null);
        axios
            .get(`/admins/${type}/${id}`)
            .then(({ data }) => {
                setUser(data);
            })
            .catch((error) => {
                navigate('/');
            });
    }, [])

    useEffect(() => {
        if (id && type) {
            fetchUsers()
        }
    }, [id, type, navigate]);

    const kickUser = useCallback(() => {
        axios.patch(`/admins/${type}/${id}/kick`,)
            .then(({ data }) => {
                if (data) {
                    setShowModal(false)
                    fetchUsers()
                    message.success(user.isKicked ? "User approved" : "User kicked");
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }, [user])

    const getUsersCSV = () => {
        axios.get(`/admins/users-download/${type}`, {
            params: {
                id
            }
        })
            .then(({ data }) => {
                const output = generateCSV(data);
                const blob = new Blob([output], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', `${id}-user.csv`);
                a.click();
            })
            .catch(err => {
                console.log(err)
            })
    }


    useEffect(() => {
        if (type === 'provider') {
            axios.get(`/admins/providers/${id}/stats`)
                .then(({ data }) => {
                    setProvidersStats(data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        if (type === 'customer') {
            axios.get(`/admins/customers/${id}/stats`)
                .then(({ data }) => {
                    setProvidersStats(data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [type, id])


    return (
        <div className='user'>
            <Modal
                width={500}
                title="Confirm your action"
                open={showModal}
                onCancel={() => setShowModal(false)}
                centered
                footer={[
                    <Button key="submit" type="primary" danger onClick={kickUser}>
                        Confirm
                    </Button>
                ]}
            >
                <p>{`Are you sure you want to ${user?.isKicked ? "approve" : "kick"} this user?`}</p>
            </Modal>
            {user && (
                <Card className="card">
                    <Descriptions title="User Info" >
                        <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
                        <Descriptions.Item label="Surname">{user.surname}</Descriptions.Item>
                        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                        <Descriptions.Item label="Registration date">{new Date(user.date_create).toLocaleDateString()}</Descriptions.Item>
                        <Descriptions.Item label="User type">{type}</Descriptions.Item>
                        <Descriptions.Item label="User is">{user.isKicked ? "kicked" : "not kicked"}</Descriptions.Item>
                        <Descriptions.Item label="Completed">{providersStats.totalCompleted}</Descriptions.Item>
                        <Descriptions.Item label="Canceled">{providersStats.totalCanceled}</Descriptions.Item>
                        <Descriptions.Item label="Late canceled">{providersStats.totalLateCanceled}</Descriptions.Item>
                    </Descriptions>
                    <div className="btn-container">
                        <Button
                            type='primary'
                            onClick={getUsersCSV}
                        >
                            Download user data
                        </Button>
                        <Button
                            className='btn'
                            onClick={() => setShowModal(true)}
                        >
                            {user.isKicked ? "Approve" : "Kick"} user
                        </Button>
                    </div>
                </Card>
            )}
        </div>
    )
}

export default User